
import { Component, Vue } from "vue-property-decorator";
import FormCardRegister from "@/components/FormCardRegister.vue";
import LogoSingle from "@/components/global/LogoSingle.vue";
import FirstLoginForm from "@/components/forms/FirstLoginForm.vue";

@Component({
  components: {
    FormCardRegister,
    LogoSingle,
    FirstLoginForm
  }
})
export default class FirstLogin extends Vue {
  private showError($event: string) {
    this.$q.notify({
      message: ("<div style='font-size:20px'>" +
        this.$t("error." + $event) +
        "</div>") as string,
      color: "principal",
      html: true,
      position: "top",
      timeout: 5000
    });
  }
}
