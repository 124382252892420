
import { Component, Prop, Vue } from "vue-property-decorator";
import LogoSingle from "@/components/global/LogoSingle.vue";
import LoginForm from "@/components/forms/LoginForm.vue";

@Component({
  components: {
    LogoSingle,
    LoginForm
  }
})
export default class Login extends Vue {
  private base_bucket = process.env.VUE_APP_AWS_ASSETS;
  private idComuna = process.env.VUE_APP_ID_COMUNA;
  private activeRedes: any = "";
  private appStore: any = "";
  private android: any = "";
  private created() {
    this.activeRedes = process.env.VUE_APP_RRSS_LOGIN;
    this.android = process.env.VUE_APP_RRSS_ANDROID;
    this.appStore = process.env.VUE_APP_RRSS_APPSTORE;
  }
  private mounted() {}
  private routerGo(name: any) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          this.$q.notify({
            message: "Ha ocurrido un error",
            color: "principal",
            position: "top",
            timeout: 5000
          });
        });
      }
    }
  }

  private irGooglePlay(url: any) {
    window.open(url);
  }

  private irAppStore(url: any) {
    window.open(url);
  }

}
