
import { Component, Vue } from "vue-property-decorator";

@Component
export default class FinishLogin extends Vue {


  private checkToken() {
    this.$axios.get('/validar-token', {
      params: {
        token: this.$route.query.token
      }
    }).then(response => {
      this.finalizarLogin();
    }).catch(error => {
      this.$q.loading.hide();
      this.$router.push("login")
    })
  }

  private finalizarLogin() {
    this.$axios.get('personas/finalizarlogin-claveunica', {
      params: {
        rut: this.$route.query.rut,
        token: this.$route.query.token
      }
    }).then(response => {
      this.$store.dispatch("login", response.data);
      this.$store.dispatch("loadStep")
      if (this.$store.state.ticket.step != 0) {
        this.$router.push({name: 'newrequest' })
      } else {
        this.$router.push({ name: "welcome" });
      }
    })
    .catch(error => {
      this.$router.push({ name: "login" });
      if (error.response.data.error.message == "auth/wrong-password") {
        this.$q.notify({
          message: "La contraseña ingresada no es correcta",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      } else if (
        error.response.data.error.message == "persona_no_rut" ||
        error.response.data.error.message == "auth/user-not-found"
      ) {
        this.$q.notify({
          message: "Rut ingresado no se encuentra registrado.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      } else if (
        error.response.data.error.message == "auth/weak-password"
      ) {
        this.$q.notify({
          message: "La contraseña ingresada no es correcta.",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      } else {
        this.$q.notify({
          message: "Ha ocurrido un problema, inténtelo más tarde",
          color: "principal",
          position: "top",
          timeout: 5000
        });
      }
    }).finally(() => {
      this.$q.loading.hide();
    })
  }

  created(){
    this.$q.loading.show();
    //this.checkToken()
    this.finalizarLogin()
  }

}

